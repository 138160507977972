@use "main" as *;

.MainHome {
    // Retirer la marge à droite créée par AOS
    overflow-x: hidden;
    //Fin AOS

    .TitrePage {
        margin: auto;
        padding: 5rem 2rem;
        max-width: 120rem;
        background: #6f8387;
        margin-bottom: 5rem;
        h1 {
            text-transform: uppercase;
        }
        h2 {
            margin-top: 1rem;
            font-size: 2rem;
            color: #1d1d1d;
        }
        p {
            margin: auto;
            padding-top: 4rem;
            max-width: 90rem;
            line-height: 170%;
            letter-spacing: 0.5rem;
        }
    }

    .BackgroundHome {
        height: 70rem;
        background: url(../images/centre.jpg) no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .ImageHome {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 120rem;
        }
    }

    .ContainerBackground:nth-of-type(even) {
        background: #dadbdc;

        .ContainerHome {

            .EquipementItem p {
                order: 2;
            }

        }
    }

    .PageGift p{
        order: 1;
}



    .ContainerBackground:nth-of-type(odd) {
        background: #b8b58c;
    }

    .BackGreyGift{
        background: #DADBDC !important;
    }

    .BackPink{
        background: #f7eeee !important;
    }

    .ContainerHome {
        max-width: 120rem;
        margin: auto;
        padding: 7rem 2rem;

        h1 {
            font-family: MerriweatherBold;
            text-transform: uppercase;
            font-size: 5rem;
            letter-spacing: 1.7rem;
            line-height: 170%;

            animation: fadeInTransformAnimation ease 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            @keyframes fadeInTransformAnimation {
                0% {
                    opacity: 0;
                    transform: translateX(-100vw);
                }
                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        h2 {
            font-family: MerriweatherBold;
            text-transform: uppercase;
            font-size: 3.5rem;
            letter-spacing: 1.4rem;
            line-height: 170%;

            opacity: 0;
            animation: fadeInAnimation ease 3s;
            animation-delay: 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            @keyframes fadeInAnimation {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        .Static {
            opacity: 1;
            animation: none;
        }

        h3 {
            font-family: MerriweatherBold;
            text-transform: uppercase;
            font-size: 3rem;
            letter-spacing: 1.2rem;
            line-height: 150%;
        }

        a {
            text-decoration: none;
            color: black;
        }

        .Presentation {
            margin-top: 10rem;
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;

            .PresentationItem {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 0 1 58rem;

                .Separateur {
                    background: #b8b58c;
                    width: 100%;
                    height: 3rem;
                }

                p {
                    font-family: Merriweather;
                    letter-spacing: 0.5rem;
                    line-height: 220%;
                    font-size: 1.7rem;
                    padding: 1rem;
                }
            }
        }

        .EquipementItem {
            margin-top: 10rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;

            p {
                flex: 0 1 58rem;
                font-family: Merriweather;
                letter-spacing: 0.5rem;
                line-height: 220%;
                font-size: 1.6rem;
                padding: 1rem;
            }

            .Item{
                flex: 0 1 55rem;
            }

            &.NoImage p {
                flex: 0 1 90rem;
            }

            a {
                flex: 0 1 58rem;
                display: flex;
            }

            &.PageItem {
                align-items: center;
            }

            li {
                padding: 1rem 0;
                a {
                    display: inline;
                    text-decoration: underline;
                }
            }
        }
    }
}

.home-button{
    max-height: fit-content;
    text-decoration: underline;
    font-style: italic;
    padding: 1%;
    font-size: 15px;
    font-weight: normal;    
    margin-top: 2%;
    margin-bottom: 2%;
    // background-color: gray;
    border-style: none;
    color:#000000;
    transition: 400ms;
    box-shadow: #000000;
    cursor: pointer;
    &:hover{
        // background-color: #FFFFFF;
        color: gray;
        // border-color:gray;
        transform:scale(1.2);
    }
}




@media only screen and (max-width: 500px) {
    .MainHome {
        .BackgroundHome {
            background-attachment: scroll;
        }

        .ContainerHome {
            h1 {
                font-size: 2.5rem;
                letter-spacing: 1.7rem;
                line-height: 170%;
            }

            h2 {
                font-size: 2rem;
                padding-top: 2rem;
                letter-spacing: 1.4rem;
                line-height: 170%;
                text-align: left;
            }

            h3 {
                font-size: 2rem;
                letter-spacing: 1.2rem;
                line-height: 150%;
            }

            .Presentation {
                margin-top: 7rem;
            }
        }
    }
}
