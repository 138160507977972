
.MainFooter{
    background: #111316;

    .ContainerFooter{
        margin: 0 auto;
        max-width: 120rem;
        padding: 7rem 2rem;
        color:#F2E9E4;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .ItemFooter{

            font-size: 1.5rem;
            letter-spacing: 0.5rem;
            display: flex;


            a{
                color:#F2E9E4;
                letter-spacing: 0.5rem;
                margin: 0 2rem;
                text-decoration: none;
    
                img{
                    margin-right: 1rem;
                }
                
            }



        }




    }
}



$animate: all 0.2s ease-in-out;


.AnimationBoutonFooter{

    .hover {
      transition: $animate;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        width: 0px;
        height: 1px;
        margin: 5px 0 0;
        transition: $animate;
        transition-duration: 0.75s;
        opacity: 0;
        background-color: #F2E9E4;
      }
      &.hover-1 {
        &:before,
        &:after {
          left: 0;
        }
      }
    }
    &:hover {
      cursor: pointer;
      .hover {
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
    }
  
  }

@media only screen and (max-width: 1000px) {
    .ContainerFooter {
      flex-wrap: wrap;
    }
    .MainFooter .ContainerFooter .ItemFooter{
        margin: 0 auto;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .AnimationBoutonFooter{
            padding: 1.5rem 0;
        }

        a{
            padding:2rem 0rem;
        }
    }
  }