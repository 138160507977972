@use "main" as *;


.MainBook{



    .ContainerBackground{
        background: #DADBDC;
        padding: 0 0 7rem 0;

        .SubContainerBackground{
            max-width: 120rem;
                margin: auto;
                padding: 5rem 2rem;

                h1{
                    font-family: MerriweatherBold;
                    text-transform: uppercase;
                    font-size: 3rem;
                    letter-spacing: 1.2rem;
                    line-height: 150%;
                    padding-bottom: 5rem;
                }


                .SubContainerItem{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    gap: 5rem;


                    .ItemChoixBook{
                        flex: 0 1 20rem;
                        cursor: pointer;

                        h2{
                            letter-spacing: 0.6rem;
                            font-size: 1.6rem;
                            padding: 2rem 0rem;
                        }
                    }
                }
        }
    }


}