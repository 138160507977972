.checkout{
  width:100%;
  padding: 20px;
  background-color: #f5f5f5;
}




.card-error{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
  
 @media (max-width: 600px) {
    .PaymentContainer{
        display: flex;
        flex-direction: row;
        justify-content:flex-start ;
        
    }
      
  }

.successMessage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: solid #253ba5;
    padding: 5%;
}


  .PaymentContainer{
    margin-right:50%;
      margin-bottom: 5%;
      
      margin-top: 1%;
  }

  .Inputtest{
      width:100%;
      border: 1px solid rgba(50, 50, 93, 0.2);
      height: 1.5em;
      font-size: 150%;

  }
  .containerElementInput{
      display: flex;
      flex-direction: column;
      margin: 4%;
      margin-bottom: 2%;
      margin-top: 2%;
      line-height: 250%;
      width: 50%;
  }


  .containerInput{
      display: flex;
      flex-direction: row;
  }

  
  

  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }

  
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.2);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  
  /* Buttons and links */
  button {
    background: #253ba5;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  button:hover {
    filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
  
