html {
  font-size: 62.5%; /* Ré-équilibrage du Rem face au Pixel pour des calculs simples */
  padding-top: 19rem;
}

body {
  font-family: Merriweather, "Arial", "Helvetica", sans-serif;
  margin: 0;
  font-size: 1.7rem;
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
  z-index: 1;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  letter-spacing: 0.7rem;
  font-size: 3rem;
}

.BoutonBlack {
  text-align: center;
  margin: 10rem 2rem;

  a {
    font-family: MerriweatherLight;
    letter-spacing: 0.5rem;
    text-decoration: none;
    background: black;
    color: white;
    padding: 2rem 4rem;
    transition: 1s;
  }
  a:hover{
    background: rgb(49, 49, 49);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%), 0 2px 2px rgb(0 0 0 / 8%), 0 4px 4px rgb(0 0 0 / 8%), 0 8px 8px rgb(0 0 0 / 8%), 0 16px 16px rgb(0 0 0 / 8%);
  }
}

// MULISH

@font-face {
  font-family: Merriweather;
  src: url(../fonts/Merriweather-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherBlack;
  src: url(../fonts/Merriweather-Black.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherBlackItalic;
  src: url(../fonts/Merriweather-BlackItalic.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherBold;
  src: url(../fonts/Merriweather-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherBoldItalic;
  src: url(../fonts/Merriweather-BoldItalic.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherItalic;
  src: url(../fonts/Merriweather-Italic.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherLight;
  src: url(../fonts/Merriweather-Light.ttf);
  font-display: swap;
}

@font-face {
  font-family: MerriweatherLightItalic;
  src: url(../fonts/Merriweather-LightItalic.ttf);
  font-display: swap;
}

//réduction des images

@media only screen and (max-width: 600px) {
  html {
    padding-top: 0;
  }
  .MainHome {
    img {
      margin:auto;
      width: 100%;
      height: 100%;
    }
  }
}
