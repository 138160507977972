@use "main" as *;

.BackGrey{
    background: #B8B58C;
}


.AdresseMain {
    margin: auto;
    max-width: 120rem;
    padding: 10rem 2rem;

    .AdresseContainer {
        display: flex;

        h4 {
            letter-spacing: 1rem;
        }

        .AdresseMainCol {
            flex: 1 1 50rem;
            text-align: center;
            margin: auto;
            width: 50%;

            .gatsby-image-wrapper {
                margin: 0 auto;
                width: 90%;
            }

            ul {
                margin: 3rem 0;
                list-style: none;
                padding-left: 0;
                line-height: 180%;
                letter-spacing: 3px;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                letter-spacing: 4px;
                text-decoration: none;
                color:black;

                img {
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .AdresseMain {
        margin: 0;
        max-width: none;


        .AdresseContainer {
            flex-direction: column;

            .AdresseMainCol{
                width: 100%;
                flex: 1 1 30rem;

                .gatsby-image-wrapper {
                    width: 30rem;
                    margin: 0 auto;
                }
            }

        }
    }
}
