

.Diaporama{

  display: flex;
  flex-direction: row;
  max-width: 55rem;
 
  width: 100%;
  justify-content: center;
}


.containerArrow{
  
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 3%;
  
}


.Diaporama_img{
  width: 100%;
  max-height: 100%;
}
.fleche_suivant{
  transform: rotate(-90deg);
}
.fleche_retour{
  
  transform: rotate(90deg);
}


@media only screen and (max-width: 500px) {
  .containerArrow{
    width: 15%;
  }
}